import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'
import type { ContentSectionStoryblok } from '@vendure/codegen/storyblok'
import { BackgroundVideo } from '@vendure/website-components/client'
import classNames from 'classnames'
import React, { useMemo } from 'react'

interface ContentSectionProps {
  blok: ContentSectionStoryblok
}

export const ContentSection: React.FC<ContentSectionProps> = ({ blok }) => {
  const backgroundClass = useMemo(() => {
    switch (blok.background) {
      case 'darkGr1':
        return 'radial-centered'
      case 'darkGr2':
        return 'bg-linear-vertical-middle'
      case 'dark':
      default:
        return
    }
  }, [blok.background])

  function renderBackgroundMedia() {
    if (blok.backgroundMedia?.filename) {
      return (
        <BackgroundVideo
          asset={blok.backgroundMedia}
          blurred
        />
      )
    }
  }

  const containerClasses = useMemo(() => {
    switch (blok.containerType) {
      case 'dense':
        return 'container max-w-screen-md'
      case 'fluid':
        return 'w-full px-4'
      case 'default':
      default:
        return 'container'
    }
  }, [blok])

  const mapSpacingClasses = (
    input: string | undefined = '',
    sm: string,
    md: string,
    lg: string,
    xl: string,
  ) => {
    switch (input) {
      case 'sm':
        return sm
      case 'md':
        return md
      case 'lg':
        return lg
      case 'xl':
        return xl
      default:
        return
    }
  }

  const innerTopSpacingTopClass = useMemo(() => {
    return mapSpacingClasses(
      blok.innerSpacingTop,
      'sm:pt-6',
      'md:pt-20',
      'lg:pt-26',
      'xl:pt-40',
    )
  }, [blok.innerSpacingTop])

  const innerTopSpacingBottomClass = useMemo(() => {
    return mapSpacingClasses(
      blok.innerSpacingTop,
      'sm:pb-6',
      'md:pb-20',
      'lg:pb-26',
      'xl:pb-40',
    )
  }, [blok.innerSpacingBottom])

  return (
    <div
      className={classNames([
        'relative py-10 md:py-20',
        backgroundClass,
        //innerTopSpacingTopClass,
        //innerTopSpacingBottomClass,
        blok.overflowHidden ? 'overflow-hidden' : '',
      ])}
      {...storyblokEditable(blok)}
    >
      {blok.backgroundMedia?.filename && renderBackgroundMedia()}
      <div className={classNames(['relative z-10', containerClasses])}>
        {blok.innerBlocks?.map((nestedBlok) => (
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
            {...{
              containerBackgroundColor: `${blok.background ?? 'white'}`,
              containerHorizontallyCentered: blok.horizontalCentered,
            }}
          />
        ))}
      </div>
    </div>
  )
}
