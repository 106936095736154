import { storyblokEditable } from '@storyblok/react/rsc'
import type { SectionHeaderStoryblok } from '@vendure/codegen/storyblok'
import { cn } from '@vendure/helpers'
import {
  PreTitle,
  RichText,
  TitleWithAccent,
} from '@vendure/website-components/client'
import classNames from 'classnames'
import { useMemo } from 'react'

interface ISectionHeader {
  blok: SectionHeaderStoryblok
  containerHorizontallyCentered?: boolean
  containerBackgroundColor?: string
}

export const SectionHeader = ({
  blok,
  containerHorizontallyCentered,
  containerBackgroundColor,
}: ISectionHeader) => {
  const hasButtons = (blok.actionButtonsRight?.length ?? 0) > 0

  const titleAndText = useMemo(() => {
    return (
      <div
        className={classNames([
          containerHorizontallyCentered ? 'text-center' : 'text-left',
          'max-w-full',
          blok.fullWidthHeading ? 'md:max-w-none' : 'max-w-screen-lg',
        ])}
      >
        {blok.preTitle && (
          <PreTitle
            containerProps={{
              className: 'mb-4',
            }}
            tag={blok.preTitleTag as any}
            button={hasButtons ? blok.actionButtonsRight?.at(0) : undefined}
          >
            {blok.preTitle}
          </PreTitle>
        )}
        <TitleWithAccent
          containerProps={{
            className: cn(
              'mb-6 text-2xl md:text-4xl lg:text-5xl',
              containerHorizontallyCentered ? 'max-w-screen-md' : '',
            ),
          }}
          tag={blok.titleTag}
          title={blok.title}
        />
      </div>
    )
  }, [blok, containerHorizontallyCentered])

  const title = useMemo(
    () => (
      <div
        className={classNames([
          'flex',
          containerHorizontallyCentered ? 'justify-center' : 'justify-start',
          'items-start md:items-center',
        ])}
      >
        {titleAndText}
      </div>
    ),
    [containerHorizontallyCentered, titleAndText],
  )

  return (
    <div
      className="mb-8 w-full"
      {...storyblokEditable(blok)}
    >
      {title}
      {blok.text && (
        <RichText
          containerProps={{
            className: classNames([
              containerHorizontallyCentered
                ? 'text-center max-w-screen-lg mx-auto'
                : 'text-left',
            ]),
          }}
          document={blok.text}
        />
      )}
    </div>
  )
}
