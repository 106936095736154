import type { SpacerStoryblok } from '@vendure/codegen/storyblok'
import classNames from 'classnames'

interface ISpacer {
  blok: SpacerStoryblok
}

// TODO: Remove this component to make spacing more standarized
export const Spacer = (props: ISpacer) => {
  const {
    blok: { sizeDesktop, sizeMobile },
  } = props

  return <div className={classNames(['block w-full'])} />
}
