import type { ButtonStoryblok } from '@vendure/codegen/storyblok'
import { ActionButton } from '@vendure/website-components/client'
import classNames from 'classnames'
import { useMemo } from 'react'

interface IStandaloneButton {
  blok: ButtonStoryblok
}

export const StandaloneButton: React.FC<IStandaloneButton> = ({ blok }) => {
  const alignment = useMemo(() => {
    switch (blok.alignment) {
      case 'flex-end':
        return 'justify-end'
      case 'flex-start':
        return 'justiy-start'
      default:
      case 'center':
        return 'justify-center'
    }
  }, [blok])
  return (
    <div className={classNames(['my-5 flex', alignment])}>
      <ActionButton {...blok} />
    </div>
  )
}
