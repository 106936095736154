import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'
import type { RowStoryblok } from '@vendure/codegen/storyblok'
import { cn } from '@vendure/helpers'
import { useMemo } from 'react'

interface IRow {
  blok: RowStoryblok
}

export const Row = ({ blok }: IRow) => {
  const alignItemsClass = useMemo(() => {
    switch (blok.verticalAlign) {
      case 'center':
        return 'items-center'
      case 'flex-start':
        return 'items-start'
      case 'flex-end':
        return 'items-end'
      default:
        return
    }
  }, [blok])

  const columnsClass = useMemo(() => {
    switch (blok.columnsPerRow) {
      case '1':
        return 'md:grid-cols-1'
      case '2':
        return 'md:grid-cols-2'
      case '4':
        return 'md:grid-cols-4'
      default:
      case '3':
        return 'md:grid-cols-3'
    }
  }, [blok])

  return (
    <div
      className={cn(
        columnsClass,
        alignItemsClass,
        'grid grid-cols-1 gap-6 md:gap-16',
      )}
      {...storyblokEditable(blok)}
    >
      {blok.columns?.map((item, i) => {
        return (
          <div
            key={item._uid}
            {...storyblokEditable(item)}
          >
            {item.items?.map((subItem) => {
              return (
                <StoryblokComponent
                  blok={subItem}
                  key={subItem._uid}
                />
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
