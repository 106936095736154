import { CommunityTeaserStoryblok } from '@vendure/codegen/storyblok'
import { cn } from '@vendure/helpers'
import { DiscordIcon, GithubIcon } from '@vendure/shared-icons'
import { Button } from '@vendure/ui-components'
import {
  CustomLink,
  PreTitle,
  RichText,
  TitleWithAccent,
} from '@vendure/website-components/client'
import { chunk } from 'lodash'
import { useMemo } from 'react'
import { CardTestimonial } from './Testimonial'

interface CommunityTeaserProps {
  blok: CommunityTeaserStoryblok
}

export function CommunityTeaser({ blok }: CommunityTeaserProps) {
  const shuffledTestimonials = useMemo(() => {
    const shuffled = blok.testimonials
      ?.map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)

    return chunk(shuffled, 2)
  }, [blok.testimonials])

  return (
    <div className="community-teaser -mx-4 md:mx-0 ">
      <div className="flex flex-col items-center gap-4 text-center">
        <PreTitle>{blok.preTitle}</PreTitle>
        <TitleWithAccent
          containerProps={{ className: 'mb-4 text-5xl' }}
          title={blok.title}
        ></TitleWithAccent>
        <RichText
          containerProps={{ className: 'max-w-sm px-4 md:px-0' }}
          document={blok.content}
        ></RichText>
        <div className="flex flex-col gap-2 md:flex-row md:gap-4">
          <Button
            variant={'outline'}
            className="border-slate-700 bg-slate-900"
            asChild
          >
            <CustomLink href={blok.githubDiscussionsTarget?.cached_url ?? ''}>
              <GithubIcon className="mr-2"></GithubIcon>
              {blok.githubDiscussionsText}
            </CustomLink>
          </Button>
          <Button
            variant={'discord'}
            asChild
          >
            <CustomLink href={blok.discordTarget?.cached_url ?? ''}>
              <DiscordIcon className="mr-2"></DiscordIcon>
              {blok.discordText}
            </CustomLink>
          </Button>
        </div>
      </div>
      <div className="relative mx-auto mt-20 flex w-full justify-center overflow-hidden">
        <div className="relative  h-[550px] w-[1360px] shrink-0 overflow-hidden">
          <div className="w-full columns-4 gap-4">
            {shuffledTestimonials.map((chunk, idx) => {
              return (
                <div
                  className={cn({
                    'translate-y-16': idx === 1,
                    'translate-y-8': idx === 2,
                  })}
                >
                  {chunk.map((item) => {
                    return (
                      <CardTestimonial
                        key={item._uid}
                        containerProps={{
                          className: 'break-inside-avoid mb-4',
                        }}
                        blok={item}
                      ></CardTestimonial>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
