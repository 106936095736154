import { storyblokEditable } from '@storyblok/react/rsc'
import type { ListStoryblok } from '@vendure/codegen/storyblok'
import classNames from 'classnames'
import { MdCheck } from 'react-icons/md'

interface IList {
  blok: ListStoryblok
  containerHorizontallyCentered?: boolean
}

export const List = ({ blok, containerHorizontallyCentered }: IList) => {
  return (
    <div
      className={classNames([
        'flex gap-3',
        blok.type === 'horizontal'
          ? 'flex-row items-center'
          : 'flex-col items-start',
        containerHorizontallyCentered ? 'justify-center' : 'justify-start',
      ])}
      {...storyblokEditable(blok)}
    >
      {blok.items?.map((item, i) => {
        return (
          <div
            className={classNames([
              'flex items-center justify-start',
              blok.style === 'check' ? 'font-semibold text-white' : '',
            ])}
            {...storyblokEditable(item)}
            key={item._uid}
          >
            {blok.style !== 'check' && (
              <div className="listItemDecorator mr-3 h-[2px] w-[10px] bg-slate-700 transition-all" />
            )}
            {blok.style === 'check' && (
              <MdCheck className="text-primary-400 mr-2 h-6 w-6"></MdCheck>
            )}
            {item.content}
          </div>
        )
      })}
    </div>
  )
}
