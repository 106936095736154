import { StoryblokComponent, type ISbStoryData } from '@storyblok/react/rsc'
import type { ConfigStoryblok, PageStoryblok } from '@vendure/codegen/storyblok'
import { StoryProvider } from '@vendure/website-components/client'
import type { StoryblokStory } from 'storyblok-generate-ts'
import { StoryblokBridge } from '../providers/StoryblokBridge'

interface IStory {
  story?: StoryblokStory<PageStoryblok | unknown> | ISbStoryData<any> | null
  config?: StoryblokStory<ConfigStoryblok>
  breadcrumbs?: Array<StoryblokStory<PageStoryblok | any>>
  draftModeEnabled?: boolean
}

export const Story = ({ story, draftModeEnabled }: IStory) => {
  return (
    <StoryProvider story={story as StoryblokStory<unknown>}>
      {draftModeEnabled && (
        <StoryblokBridge blok={story?.content}></StoryblokBridge>
      )}
      {!draftModeEnabled && <StoryblokComponent blok={story?.content} />}
    </StoryProvider>
  )
}
