import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'
import type { PageStoryblok } from '@vendure/codegen/storyblok'
import type React from 'react'

interface PageProps {
  blok: PageStoryblok
}

export const Page: React.FC<PageProps> = ({ blok }) => {
  return (
    <main {...storyblokEditable(blok)}>
      {blok.blocks?.map((nestedBlok) => (
        <StoryblokComponent
          blok={nestedBlok}
          key={nestedBlok._uid}
        />
      ))}
    </main>
  )
}
