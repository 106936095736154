import { FullWidthImageStoryblok } from '@vendure/codegen/storyblok'
import { CustomImage, GradientCard } from '@vendure/website-components/client'

interface IFullWidthImage {
  blok: FullWidthImageStoryblok
}

export const FullWidthImage: React.FC<IFullWidthImage> = ({ blok }) => {
  return (
    <GradientCard outerProps={{ className: 'w-full' }}>
      {blok.imageMobile?.filename && (
        <CustomImage
          width={1500}
          height={800}
          className="block h-auto w-full md:hidden"
          src={blok.imageMobile.filename}
          alt={blok.imageMobile.alt ?? ''}
        />
      )}
      {blok.imageDesktop?.filename && (
        <CustomImage
          width={1500}
          height={800}
          className="hidden h-auto w-full md:block"
          src={blok.imageDesktop.filename}
          alt={blok.imageDesktop.alt ?? ''}
        />
      )}
    </GradientCard>
  )
}
